<template>
  <div class="tags">
    <div v-for="wagon in wagons" :key="wagon">
      <div v-for="shelve in shelves" :key="shelve">
        <div class="tag" v-for="place in places" :key="place">
          <div class="tag__numbers">
            <div class="tag__aisle-and-unit">
              <span class="tag__number tag__number--unit">W</span>
              <span class="tag__number tag__number--unit">{{ wagon }}</span>
            </div>
            <div class="tag__shelve-and-place" :class="`tag__shelve-and-place--${shelve}`">
              <span class="tag__number tag__number--shelve">{{ shelve }}</span>
              <span class="tag__number tag__number--place">{{ place }}</span>
            </div>
          </div>
          <div class="tag__barcode-container">
            <svg class="barcode tag__barcode" :jsbarcode-value="`W${wagon}-${shelve}${place}`"></svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'

export default {
  data() {
    return {
      wagons: [2],
      shelves: [1],
      places: [1, 2, 3, 4, 5, 6, 7, 8]
    }
  },
  mounted() {
    JsBarcode('.barcode').options({ format: 'CODE128A', displayValue: false, width: 3 }).init()
  }
}
</script>

<style lang="scss">
@media print {
  @page {
    size: A4;
    margin: 5mm;
  }
}

.tag {
  width: 78mm;
  height: 18mm;
  border: 0.2mm solid black;
  border-left: 0;
  display: flex;
  float: left;
  justify-content: space-between;
  align-items: center;
  page-break-inside: avoid;

  &__numbers {
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  &__aisle-and-unit {
    background: #ccc;
    color: #000;
    display: flex;
    padding: 0 1.5mm;
    height: 18mm;
    text-align: center;
    justify-content: center;
    line-height: 5mm;
    font-size: 14px;
    flex-direction: column;
  }

  &__shelve-and-place {
    font-size: 48px;
    background: #ccc;
    height: 18mm;
    line-height: 18mm;
    padding: 0 3mm;

    &--1 {
      background: #cfc;
    }

    &--2 {
      background: #ccf;
    }

    &--3 {
      background: #fcc;
    }

    &--4 {
      background: #cff;
    }

    &--5 {
      background: #fcf;
    }

    &--6 {
      background: #ffc;
    }

    .tag__number {
      display: inline-block;
      text-align: center;
    }
  }

  &__barcode-container {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }

  &__barcode {
    width: auto;
    height: 17mm;
  }
}
</style>
